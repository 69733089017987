<template>
    <div>
        <el-menu
      default-active="/"
      class="el-menu-vertical-demo"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      router>

      <el-menu-item index="/">
        <i class="el-icon-menu"></i>
        <span slot="title">首页</span>
      </el-menu-item>

      <el-submenu index="/outLink">
        <template slot="title">
          <i class="el-icon-s-operation"></i>
          <span>微信外链</span>
        </template>
        <el-menu-item-group>
            <el-menu-item index="/outLink/out">
                <i class="el-icon-position"></i>
                <span>我的外链</span>
            </el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <el-submenu index="/tiktokCard">
        <template slot="title">
          <i class="el-icon-postcard"></i>
          <span>抖音卡片</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/tiktokCard/card">
            <i class="el-icon-bank-card"></i>
            <span>我的卡片</span>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <el-submenu index="/shortLink">
        <template slot="title">
          <i class="el-icon-paperclip"></i>
          <span>短链接</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/shortLink/short">
            <i class="el-icon-attract"></i>
            <span>我的短链</span>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <el-submenu index="/redBookLink">
        <template slot="title">
          <i class="el-icon-notebook-1"></i>
          <span>小红书外链</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/redBookLink/red">
            <i class="el-icon-link"></i>
            <span>我的小红书外链</span>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <el-menu-item index="/products/vip">
        <i class="el-icon-star-off"></i>
        <span slot="title">会员中心</span>
      </el-menu-item>
      <el-menu-item index="/inviteLog">
        <i class="el-icon-share"></i>
        <span slot="title">推广计划</span>
      </el-menu-item>
    </el-menu>
    </div>
</template>
 
<script>
export default {

}
</script>

<style>

</style>