<template>
    <div>
        <div class="login-box">
            <el-card class="login-card" shadow="always" body-style="padding:0px;">
                <div class="login-card-header">
                    <div class="login-card-title">
                        <span>节点链接</span>
                    </div>
                    <div class="login-card-desc">
                        <span>开启你的从公域到私域的丝滑获客体验</span>
                    </div>
                </div>
                <div class="cutLine"></div>
                <div class="login-card-body">
                    <div class="login-card-body-title">
                        <p>请使用微信扫码进行登录</p>
                    </div>
                    <div class="login-card-body-img">
                        <el-image :src="qrcodeImg" fit="fit"></el-image>
                    </div>
                    <div class="login-card-body-text">
                        <p>二维码有效期一分钟，如超时请刷新页面</p>
                    </div>
                    <div class="login-card-body-text">
                        <p>扫码关注公众号，自动完成登录，未注册用户，将自动注册。</p>
                    </div>
                </div>
                
            </el-card>
        </div>
    </div>
</template>

<script>
import { generateQrCodeUrl } from '@/api/link';
import { login } from '@/api/linkUser';
import { setLinkToken,getLinkToken } from '@/utils/auth'
export default {
    data(){
        return{
            qrcodeImg: undefined,
            token: undefined,
            ticket: undefined,
            inviteCode: undefined,
            timer: undefined
        }
    },
    created(){
        this.generateQrCodeUrl();
       
    },
    mounted() {
        this.getAffiliateCode();
        //this.login();
        this.timer = window.setInterval(() => {
            setTimeout(() => {
                this.login()
            },0)
        },3000)
    },
    methods:{

        //获取邀请码
        getAffiliateCode(){
            // 获取当前页面的 URL
            const url = new URL(window.location.href);
            //console.log('获取到的链接wei',url)
            const aff = this.$route.query.aff
            if (aff) {
                this.inviteCode = aff;
                // 你可以在这里实现根据 affiliateCode 需要完成的功能
                console.log('Affiliate Code:', this.inviteCode);
            }
        },

        //获取微信登录二维码
        generateQrCodeUrl(){
            generateQrCodeUrl().then(res =>{
                this.qrcodeImg = res.data.img;
                this.ticket = res.data.ticket;
            });
        },

        //轮询登录
        login(){
            //console.log('this.ticket',this.ticket)
            let data = {
                ticket: this.ticket
            }
            if(this.inviteCode != null || this.inviteCode != undefined){
                data.inviteCode = this.inviteCode
            }
            //console.log("邀请码",data.inviteCode)
            login(data).then(res=>{
                //console.log('loginRes',res)
                if(res.code === 10092){
                    //二维码超时，清除定时器，弹窗刷新页面
                    clearInterval(this.timer);
                    this.refreashPage();
                }else if(res.code === 200){
                    //清除定时器
                    clearInterval(this.timer);
                    //扫码成功，存入缓存，页面跳转到主页
                    setLinkToken(res.data);
                    //加载页面
                    const loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    setTimeout(() => {
                        loading.close();
                        this.$router.replace({name:'Home'})
                    }, 1000);
                    //console.log(getLinkToken)
                   
                }
            }); 
        },

        //二维码超时刷新页面
        refreashPage(){
            this.$alert('二维码超时过期,是否重新获取?', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    location.reload();
                    this.$message({
                    type: 'success',
                    message: '刷新成功!'
                    });
                }
            });

        }
    },
    destroyed() {
        window.clearInterval(this.timer)  // 页面销毁后清空轮询
    }
}
</script>

<style lang="less" scoped>
    .login-box{
        display: flex;
        flex-direction: column;
        //justify-content:center;
        align-items: center;
        margin-top:100px;

        .login-card{
            width:450px;
            height:600px;
            //background-color: #F9FAFB;
            //background-color: #FFFFFF;

            .login-card-header{
                background-color: #FFFFFF;
               
                height:60px;
                width:100%;

                .login-card-title{
                    margin-top:30px;
                    text-align: center;
                    font-weight: bold;
                    font-size: 24px;
                }

                .login-card-desc{
                    margin-top:10px;
                    text-align: center;
                }
            }

            .login-card-body{
                width:100%;
                height: 500px;
                background-color: #F9FAFB; 

                .login-card-body-title{
                    padding-top: 10px;
                    text-align: center;
                }

                .login-card-body-img{
                    margin-left:80px;
                    margin-top:20px;
                    width:300px;
                    height:300px;
                }

                .login-card-body-text{
                    text-align: center;
                    margin-top:20px;
                }
            }

            
        }

        .cutLine{
            margin-top: 10px;
            border: 1px solid grey;
        }
    }
</style>