//import Cookies from 'js-cookie'

const TokenKey = 'WechatAuthorization'

/* 存储数据 */
export const setLinkToken = (value) => {
  // 将数组、对象类型的数据转换为 JSON 格式字符串进行存储
  if (typeof value === "object") {
    value = JSON.stringify(value);
  }
  window.localStorage.setItem(TokenKey, value);
};

/* 获取数据 */
export const getLinkToken = () => {
  const data = window.localStorage.getItem(TokenKey);
  try {
    return JSON.parse(data);
  } catch (err) {
    return data;
  }
};

/* 删除数据 */
export const removeLinkToken = () => {
  window.localStorage.removeItem(TokenKey);
};
