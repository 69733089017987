import request from '@/utils/request'
const prefix = '/api/linkUserProducts'

// 获取用户会员详情信息
export function getUserProductDetails() {
  return request({
    url: prefix + '/getUserProductDetails',
    method: 'get',
  })
}


