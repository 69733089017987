<template>
    <div class="layout">
        <!-- 左侧导航区域 -->
        <LeftMenu class="menu"/>

        <!-- 中间内容区域 -->
        <CenterContent class="content"/>
    </div>
</template>

<script>
import LeftMenu from '../Layout/LeftMenu.vue'
import CenterContent from '../Layout/CenterContent.vue'
export default {
    components:{
        LeftMenu,
        CenterContent
    }
}
</script>

<style lang='less' scoped>
    .layout{
        .menu{
            width:200px;
            min-height:500px;
            background: #666;
            position: fixed;
            top:0;
            bottom:0;
        }
        .content{
            margin-left: 200px;
        }
    }

</style>