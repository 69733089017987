<template>
    <div>
        <div class="header">
            <div class="header-box">
                <div class="header-vip">
                    <el-badge value="hot" class="item">
                        <el-button size="small" type="warning" plain><router-link :to="{name:'VIP'}">升级会员</router-link></el-button>
                    </el-badge>
                </div>
                <el-dropdown trigger="click" @command="handleCommand">
                    <div class="header-img-text">
                        <div class="header-img">
                            <el-avatar shape="circle" size="medium" :src="userInfo.headUrl"></el-avatar>
                        </div>
                        <div class="header-name">
                            <span>{{ userInfo.nickName }}</span><i class="el-icon-arrow-down el-icon--right"></i>
                        </div>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <!-- <el-dropdown-item icon="el-icon-user-solid" command="updateUser">修改信息</el-dropdown-item> -->
                        <el-dropdown-item icon="el-icon-error" command="logout">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            
        </div>
        <!-- 内容区域路由出口 -->
        <div class="content">
            <router-view/>
        </div>
    </div>
</template>

<script>
import { getUserDetails,logout } from "@/api/linkUser";
export default {
    data(){
        return{
            userInfo: {
                headUrl: undefined
            }
        }
    },
    created(){
        this.getUserInfo();
    },
    methods:{

        //获取用户信息
        getUserInfo(){
            getUserDetails().then(res =>{
                //console.log('userInfo',res)
                this.userInfo = res.data
            });
        },

        handleCommand(command) {
            console.log(command)
            if(command == 'logout'){
                this.logout();
            }else if(command == 'updateUser'){
                console.log('更新用户信息')
            }else{
                console.log('嘀嘀嘀')
            }
            
        },

        //退出登录
        logout(){
            //弹窗确认
            this.$confirm('确定要退出登录吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                logout().then(res =>{
                    console.log('注销了',res)
                    if(res.code == 200){
                        this.$message({
                            type: 'success',
                            message: '退出成功!'
                        });
                        location.href = '/login';
                    }
                   
                });
                
        })
            
        }
    }
}
</script>

<style lang="less" scoped>

    .header{
        background: #FFFFFF;
        height:60px;
       

        .header-box{
            display: flex;
            flex-direction: row;
            justify-content: right;

            .header-vip{
                margin-top:15px;
                margin-right:30px;
            }

            .header-img-text{
                //border:1px solid red;
                display: flex;
                flex-direction: row;
                margin-right:20px;
            }

            .header-img{
                margin-top: 10px;
                //margin-right: 50px;
                width:38px;
                height:38px;
                //border:1px solid green;
            }

            .header-name{
                //border:1px solid black;
                height:20px;
                text-align: center;
                margin-top: 20px;
                margin-left: 10px;
                font-weight: bold;
                //margin-right: 30px;
            }
        }

    }
    .content{
        // background-color: #f1f6fd;
        // height:100%;
    }
</style>