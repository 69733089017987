import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../components/Layout/index.vue'
import Home from '../views/Home/Home.vue'
import Login from '../views/Login/Login.vue'
import { getLinkToken } from '@/utils/auth'

//异步加载
const ShortLink = ()=>import('../views/ShortLink/ShortLink.vue')
const ShortLinkList = ()=>import('../views/ShortLink/ShortLinkList/ShortLinkList.vue')
const OutLink = ()=>import('../views/OutLink/OutLink.vue')
const OutLinkList = ()=>import('../views/OutLink/OutLinkList/OutLinkList.vue')
const TiktokCard = ()=>import('../views/TiktokCard/TiktokCard.vue')
const TiktokCardList = ()=>import('../views/TiktokCard/TiktokCardList/TiktokCardList.vue')
const RedBookLink = ()=>import('../views/RedBookLink/RedBookLink.vue')
const RedBookLinkList = ()=>import('../views/RedBookLink/RedBookLinkList/RedBookLinkList.vue')
const Products = ()=>import('../views/Products/Products.vue')
const ProductsList = ()=>import('../views/Products/ProductsList/ProductsList.vue')
const InviteLog = ()=>import('../views/inviteLog/InviteLog.vue')
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component:Login
  },
  {
    path: '',
    component:Layout,
    children:[
      {
        path: '/',
        name: 'Home',
        component:Home
      },
      
      {
        path: '/outLink',
        name: 'OutLink',
        component: OutLink,
        reditect: '/outLink/out',
        children:[
          {
            path:'out',
            component: OutLinkList
          }
        ]
      },
      {
        path: '/tiktokCard',
        name: 'TiktokCard',
        component: TiktokCard,
        reditect: '/tiktokCard/card',
        children:[
          {
            path:'card',
            component: TiktokCardList
          }
        ]
      },
      {
        path: '/shortLink',
        name: 'ShortLink',
        component: ShortLink,
        reditect: '/shortLink/short',
        children:[
          {
            path:'short',
            component: ShortLinkList
          }
        ]
      },
      {
        path: '/redBookLink',
        name: 'RedBookLink',
        component: RedBookLink,
        reditect: '/redBookLink/red',
        children:[
          {
            path:'red',
            component: RedBookLinkList
          }
        ]
      },
      {
        path: '/products',
        name: 'Products',
        component: Products,
        reditect: '/products/vip',
        children:[
          {
            name: 'VIP',
            path:'vip',
            component: ProductsList
          }
        ]
      },
      {
        path: '/inviteLog',
        name: 'InviteLog',
        component: InviteLog
      },
    ]
  }
]

const router = new VueRouter({
  mode: "history",
  routes
})

router.beforeEach((to, from, next) => {
  //to 将要访问的路径
  //from 代表从哪个路径而来
  //next() 代表放行 next('xxx') 强制放行的xxx的路径
  if(to.path==='/login'){
      next();
  }else if(to.path === '/register' && to.query.aff){
    const { aff } = to.query;
      // 重定向到登录页，并带上 aff 参数
      next({ path: '/login', query: { aff } });
  }else{
      const tokenStr = getLinkToken('WechatAuthorization');
      //console.log(tokenStr);
      if(!tokenStr){
          return next('/login')
         }
      next();
  }
})

export default router
