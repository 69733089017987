<template>
    <div>
       <div class="bannerBox">
            <el-image class="banner-img" :src="this.homeBanner" fit="fill" @click="showPersonalImg" data-umami-event="user-jump-home"></el-image>
       </div>
       <div class="center-box">
            <div class="member-product-info">
                <el-card class="member-product-card">
                    <p class="member-product-title">会员情况</p>
                    <el-divider></el-divider>
                    <div class="member-product-sub">
                        <p>会员类型：<span class="member-product-text">{{ this.vipInfo.userProducts.productName }}</span></p>
                        <p>开始日期：<span class="member-product-text">{{ this.vipInfo.userProducts.startTime }}</span></p>
                        <p>结束日期：<span class="member-product-red">{{ this.vipInfo.userProducts.endTime }}</span></p>
                    </div>
                </el-card>
                <el-card class="member-product-card">
                    <p class="member-product-statistics">链接可创建数量</p>
                    <el-divider></el-divider>
                    <div class="member-product-sub">
                        <p>抖音卡片：<span class="member-product-number">{{ this.vipInfo.tiktokCardNum }}</span></p>
                        <p>微信链接：<span class="member-product-number">{{ this.vipInfo.outUrlsNum }}</span></p>
                        <p>简短链接：<span class="member-product-number">{{ this.vipInfo.shortUrlsNum }}</span></p>
                        <p>小红书链接：<span class="member-product-number">{{ this.vipInfo.redbookNum }}</span></p>
                    </div>
                </el-card>
                <el-card class="member-product-card">
                    <p class="member-product-statistics">本月微信外链</p>
                    <p class="member-product-statistics">已访问次数</p>
                    <el-divider></el-divider>
                    <p class="member-product-number">{{ this.statisticsInfo.outUrlMonthNum }}</p>
                </el-card>
                <el-card class="member-product-card">
                    <p class="member-product-statistics">本月抖音卡片</p>
                    <p class="member-product-statistics">已访问次数</p>
                    <el-divider></el-divider>
                    <p class="member-product-number">{{ this.statisticsInfo.cardMonthNum }}</p>
                </el-card>    
                <el-card class="member-product-card">
                    <p class="member-product-statistics">本月短链接</p>
                    <p class="member-product-statistics">已访问次数</p>
                    <el-divider></el-divider>
                    <p class="member-product-number">{{ this.statisticsInfo.shortUrlMonthNum }}</p>
                </el-card>
                <el-card class="member-product-card">
                    <p class="member-product-statistics">本月小红书外链</p>
                    <p class="member-product-statistics">已访问次数</p>
                    <el-divider></el-divider>
                    <p class="member-product-number">{{ this.statisticsInfo.redBookUrlNum }}</p>
                </el-card>
            </div>
       </div>
            <!-- 联系方式展示对话框 -->
        <el-dialog :visible.sync="open" append-to-body width="780px">
            <div class="pannel-box">
                <p class="pannel-text">请使用微信扫码联系客服获取详细信息</p>
                <p class="pannel-text">备注：节点链接</p>
                <el-image class="personal-img" :src="this.customerUrl" fit="contain"></el-image>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getUserProductDetails} from '@/api/linkUserProducts';
import { getStatisticsResult } from '@/api/link';
export default {
    data(){
        return{
            vipInfo: {
                outUrlsNum: undefined,
                redbookNum: undefined,
                shortUrlsNum: undefined,
                tiktokCardNum: undefined,
                userProducts:{
                    createTime: undefined,
                    endTime: undefined,
                    id: undefined,
                    productId: undefined,
                    productName: undefined,
                    startTime: undefined,
                    userId: undefined
                }
            },
            homeBanner: 'https://img.bnyer.cn/links/home_banner.png',
            customerUrl: 'https://img.bnyer.cn/links/personal.jpg',
            open: false,
            statisticsInfo: {}
        }
    },
    created(){
        this.getStatisticsResult();
        this.getUserProductDetails();
    },
    methods:{
        //获取用户会员信息
        getUserProductDetails(){
            getUserProductDetails().then(res =>{
                this.vipInfo = res.data;
                //console.log(this.vipInfo);
            });
        },

        //展示个人二维码
        showPersonalImg(){
            this.open = true;
        },

        //查看相关链接统计数据
        getStatisticsResult(){
            getStatisticsResult().then(res =>{
                //console.log('res',res)
                this.statisticsInfo = res.data
            });
        }
    }
}
</script>

<style lang="less" scoped>
    .bannerBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        cursor:pointer;

        .banner-img{
            //margin: 20px 20px 20px 20px;
            // width:1200px;
            width:100%;
            height:400px;
        }
       
    }

    

    .pannel-box{
        display: flex;
        flex-direction: column;
        align-items: center;
        width:780px;
        height:500px;
        text-align: center;
        .pannel-text{
          font-size: 24px;
          font-weight: bold;
        }
    }

    .center-box{
        .member-product-info{
            display: flex;
            flex-direction: row; 
            justify-content: space-around;

            .member-product-card{
                width: 200px;
                height: auto;
                text-align: center;

                .member-product-number{
                    font-weight: bold;
                    font-size: 24px;
                    color:red;
                }

                .member-product-sub{
                    display: flex;
                    flex-direction: column; 
                    align-items: flex-start;

                    .member-product-text{
                        font-weight: bold;
                    }

                    .member-product-red{
                        font-weight: bold;
                        color: red;
                    }
                }

                .member-product-title{
                    font-size: 24px;
                    font-weight: bold;
                    color: #007BFF;
                }

                .member-product-statistics{
                    font-size: 20px;
                    font-weight: bold;
                    color: #007BFF;
                }

                
            }
        }
    }
</style>