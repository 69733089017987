import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import dict from './modules/dict'
import tagsView from './modules/tagsView'
import getters from './getters'
import { getLinkToken } from '@/utils/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state:{
    //存储token
    token: getLinkToken() ? getLinkToken() : ''
  },
  mutations:{
      //登录后通过setToken存入token token保存在state和localStorage中
      setToken (state,token) {
        state.token =token;
        setLinkToken(token.token);     //存储token
      },
      //登出后通过delToken清除token
      delToken (state) {
        state.token = '';
        removeLinkToken();    //删除token
      }
  },
  modules: {
    app,
    dict,
    tagsView
  },
  getters
})
