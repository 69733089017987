import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './assets/css/reset.css'
import { parseTime} from "@/utils/TimeUtils";
import modal from './utils/modal.js'

// 分页组件
import Pagination from "@/components/Pagination/pagination";

// 全局方法挂载
Vue.prototype.parseTime = parseTime
// 模态框对象
Vue.prototype.$modal = modal

// 全局组件挂载
Vue.component('Pagination', Pagination)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
