import request from '@/utils/request'
const prefix = '/api/linkUser'

// 获取群流宝用户信息
export function getUserDetails() {
  return request({
    url: prefix + '/details',
    method: 'get'
  })
}

// 用户登录
export function login(data) {
  return request({
    url: prefix + '/login',
    method: 'post',
    data: data
  })
}

// 用户注销
export function logout() {
  return request({
    url: prefix + '/logout',
    method: 'get',
  })
}
