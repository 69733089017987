import request from '@/utils/request'
const prefix = '/api/link'

// 获取公众号登录二维码
export function generateQrCodeUrl() {
  return request({
    url: prefix + '/generateQrCodeUrl',
    method: 'get'
  })
}

// 创建微信预支付订单
export function wxPay(data) {
  return request({
    url: prefix + '/wxPay',
    method: 'post',
    data: data
  })
}

// 支付宝网页支付
export function aliWebPay(data) {
  return request({
    url: prefix + '/aliWebPay',
    method: 'post',
    data: data
  })
}

// 接收微信支付回调消息
export function wxPayNotify() {
  return request({
    url: prefix + '/wxPayNotify',
    method: 'get'
  })
}

// 接收支付宝回调消息
export function aliPayNotify() {
  return request({
    url: prefix + '/aliPayNotify',
    method: 'post'
  })
}

// 查看相关链接统计数据
export function getStatisticsResult() {
  return request({
    url: prefix + '/getStatisticsResult',
    method: 'get'
  })
}
