/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios';
import QS from 'qs';
import { Notification,Message, MessageBox } from 'element-ui'
import errorCode from '@/utils/errorCode'
import store from '../store/index'
import { getLinkToken } from '@/utils/auth'
import { logout } from '@/api/linkUser'

// 是否显示重新登录
export let isRelogin = { show: false };
 
// 环境的切换
// if (process.env.NODE_ENV == 'development') {    
//     axios.defaults.baseURL = '/api';
// } else if (process.env.NODE_ENV == 'debug') {    
//     axios.defaults.baseURL = '';
// } else if (process.env.NODE_ENV == 'production') {    
//     axios.defaults.baseURL = 'http://api.123dailu.com/';
// }
 
// 创建axios的对象
const service = axios.create({
  baseURL: "https://tc.bnyer.cn",  //配置固定域名 //https://tc.bnyer.cn http://localhost:9201
  timeout: 10000
})
 
// post请求头
service.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
 
// 请求拦截器
service.interceptors.request.use(    
    config => {
        // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        // const token = store.state.token;        
        // token && (config.headers.Authorization = token);        
        // return config;  
        // 是否需要设置 token
      if(getLinkToken()){
        config.headers.WechatAuthorization = getLinkToken();
      }
      return config;
    },    
    error => {        
        return Promise.error(error);    
    })
 
// 响应拦截器
// service.interceptors.response.use(    
//     response => {        
//         if (response.data.code === 200) {            
//             return Promise.resolve(response);        
//         } else {            
//             //return Promise.reject(response);  
//         }    
//     },
//     // 服务器状态码不是200的情况    
//     error => {        
//         if (error.response.data.code) {            
//             switch (error.data.code) {                
//                 // 401: 未登录                
//                 // 未登录则跳转登录页面，并携带当前页面的路径                
//                 // 在登录成功后返回当前页面，这一步需要在登录页操作。                
//                 case 401:                    
//                     router.replace({                        
//                         path: '/login',                        
//                         query: { redirect: router.currentRoute.fullPath } 
//                     });
//                     break;
//                 // 403 token过期                
//                 // 登录过期对用户进行提示                
//                 // 清除本地token和清空vuex中token对象                
//                 // 跳转登录页面                
//                 case 403:                     
//                 MessageBox({                        
//                         message: '登录过期，请重新登录',                        
//                         duration: 1000,                        
//                     });                    
//                     // 清除token                    
//                     localStorage.removeItem('token');                    
//                     store.commit('loginSuccess', null);                    
//                     // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
//                     setTimeout(() => {                        
//                         router.replace({                            
//                             path: '/login',                            
//                             query: { 
//                                 redirect: router.currentRoute.fullPath 
//                             }                        
//                         });                    
//                     }, 1000);                    
//                     break; 
//                 // 404请求不存在                
//                 case 404:                    
//                 MessageBox({                        
//                         message: '网络请求不存在',                        
//                         duration: 1500,                        
//                     });                    
//                 break;
//                 case 500:
//                 MessageBox({                        
//                         message: '网络错误',                        
//                         duration: 1500,                        
//                     });                    
//                 break;
//                 // 其他错误，直接抛出错误提示                
//                 default:                    
//                 MessageBox({                        
//                         message: error.response.data.msg,                        
//                         duration: 1500,                        
//                     });            
//             }            
//             return Promise.reject(error.response);        
//         }       
//     }
// );

service.interceptors.response.use(res => {
    //console.log('resAAA',res)
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    // 二进制数据则直接返回
    if (res.request.responseType ===  'blob' || res.request.responseType ===  'arraybuffer') {
      return res.data
    }
    if (code === 401) {
      if (!isRelogin.show) {
        isRelogin.show = true;
        MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', { confirmButtonText: '重新登录', cancelButtonText: '取消', type: 'warning'}).then(() => {
          isRelogin.show = false;
          //调用注销账号方法
          location.href = '/login';
          // logout().then(res=>{
            
          // });
      }).catch(() => {
        isRelogin.show = false;
      });
    }
      return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    } else if (code === 500) {
      Message({ message: msg, type: 'error' })
      return Promise.reject(new Error(msg))
    } else if(code === 10092){
        return res.data
    }else if(code === 10093){
        return res.data
    }else if(code === 10094){
        return res.data
    }else if(code === 10095){
        return res.data
    }else if(code === 10096){
        return res.data
    }else if(code === 10097){
      return res.data
    }else if (code === 601) {
      Message({ message: msg, type: 'warning' })
      return Promise.reject('error')
    } else if (code !== 200) {
      Notification.error({ title: msg })
      return Promise.reject('error')
    } else {
      return res.data
    }
  },
  error => {
    //console.log('err' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({ message: message, type: 'error', duration: 5 * 1000 })
    return Promise.reject(error)
  }
)
export function get(url, params){    
    return new Promise((resolve, reject) =>{        
      service.get(url, {            
            params: params        
        })        
        .then(res => {            
            resolve(res.data);        
        })        
        .catch(err => {            
            reject(err.data)        
        })    
    });
}
export function post(url, params) {    
    return new Promise((resolve, reject) => {         
      service.post(url, QS.stringify(params))        
        .then(res => {            
            resolve(res.data);        
        })        
        .catch(err => {            
            reject(err.data)        
        })    
    });
}

export default service;
